<template>
  <b-card>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.prevent="handleSubmit(onSubmit)" enctype="multipart/form-data">
        <b-tabs pills>
          <b-tab active>
            <template #title>
              <feather-icon icon="MapPinIcon"/>
              <span>Bölge Bilgileri</span>
            </template>

            <b-row class="mt-1">
              <b-col cols="12">
                <validation-provider #default="validationContext" name="Kod" rules="required">
                  <b-form-group label="Kod">
                    <b-form-input trim placeholder="Kod" v-model="dataInfo.code" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12">
                <validation-provider #default="validationContext" name="İsim" rules="required">
                  <b-form-group label="İsim">
                    <b-form-input trim placeholder="İsim" v-model="dataInfo.name" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12">
                <validation-provider #default="validationContext" name="Durum" rules="required">
                  <b-form-group label="Durum" label-for="isActive" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.isActive" :options="statusOptions" :reduce="val => val.value" :clearable="false"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

          </b-tab>

          <b-tab>
            <template #title>
              <feather-icon icon="UsersIcon"/>
              <span>Bölge Müdürleri</span>
            </template>

            <b-alert variant="warning" show class="mb-1">
              <div class="alert-body font-small-2">
                <p>
                  <small class="mr-50"><span class="font-weight-bold">Seçilen kullanıcılara aktivite girişi, aksiyon girişi, müşteri şikayeti girişi ve form talepleri sırasında bildirim gönderilecektir.</span></small>
                </p>
              </div>
            </b-alert>

            <b-card no-body class="border mt-1">
              <b-table
                  :items="dataInfo.users"
                  :fields="userFields"
                  class="mb-0"
                  striped responsive bordered hover>
                <template #cell(profilePicture)="data">
                  <b-avatar
                      ref="previewEl"
                      :src="getApiFile(data.value)"
                      :text="avatarText(data.item.name)"
                      :variant="`light-primary`"
                      size="30px"
                  />
                </template>
                <template #cell(isSelected)="data">
                  <b-form-checkbox :checked="data.value" @change="changeUserValue(data.item)"/>
                </template>
              </b-table>
            </b-card>
          </b-tab>
        </b-tabs>
        <action-buttons :back-route="'definition-region-list'"/>
      </b-form>
    </validation-observer>
    <Overlay :busy="busy"></Overlay>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback, BAlert, BTable, BAvatar, BTabs, BTab, BFormCheckbox
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {required} from '@validations'
import {onUnmounted, ref} from '@vue/composition-api'
import vSelect from 'vue-select'
import store from "@/store"
import storeModule from "@/views/definition/region/store"
import router from '@/router'
import {useToast} from 'vue-toastification/composition'
import {statusOptions, avatarText, getApiFile} from "@core/utils/filter"
import Overlay from "@/components/Overlay.vue";
import ActionButtons from "@/components/Form/ActionButtons.vue";
import {toastMessage} from "@core/utils/utils";

export default {
  components: {
    BFormCheckbox,
    BTab,
    BTabs,
    BAvatar,
    BTable,
    BAlert,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,

    ActionButtons,
    Overlay,
    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  props: {},
  data() {
    return {
      required,
    }
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const toast = useToast()
    const busy = ref(false)
    const dataInfo = ref({
      id: 0,
      code: '',
      name: '',
      isActive: true,
      users: []
    })

    const onSubmit = () => {
      busy.value = true
      store.dispatch('store/' + (router.currentRoute.params.id > 0 ? 'editItem' : 'addItem'), dataInfo.value).then(response => {
        toastMessage(toast, 'success', response.data.message)
        router.push({name: 'definition-region-list'})
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
      }).finally(message => {
        busy.value = false
      })
    }

    const userFields = [
      {key: 'profilePicture', label: 'Profil', thStyle: {width: "5%"}},
      {key: 'name', label: 'Ad Soyad'},
      {key: 'isSelected', label: 'Seçim', thStyle: {width: "10%"}}
    ];

    const changeUserValue = (target) => {
      dataInfo.value.users.forEach(function callback(value, index) {
        if (value.id === target.id) {
          value.isSelected = !target.isSelected
        }
      })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    busy.value = true
    store.dispatch('store/fetchItem', {id: router.currentRoute.params.id > 0 ? router.currentRoute.params.id : 0}).then(response => {
      dataInfo.value = response.data.data
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
      if (error.response.status === 403) {
        router.push({name: 'definition-region-list'})
      }
    }).finally(message => {
      busy.value = false
    })

    return {
      busy,
      dataInfo,
      refFormObserver,
      userFields,

      statusOptions,

      onSubmit,
      getValidationState,
      resetForm,
      changeUserValue,
      avatarText,
      getApiFile,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
